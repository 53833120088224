export const getUTCTime = (inputTime: number): string => {
  const date = new Date(inputTime);
  const Y = date.getUTCFullYear();
  const M = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1;
  let mouth = '';

  switch (M.toString()) {
    case '01':
      mouth = 'Jan-';
      break;
    case '02':
      mouth = 'Feb-';
      break;
    case '03':
      mouth = 'Mar-';
      break;
    case '04':
      mouth = 'Apr-';
      break;
    case '05':
      mouth = 'May-';
      break;
    case '06':
      mouth = 'Jun-';
      break;
    case '07':
      mouth = 'Jul-';
      break;
    case '08':
      mouth = 'Aug-';
      break;
    case '09':
      mouth = 'Sep-';
      break;
    case '10':
      mouth = 'Oct-';
      break;
    case '11':
      mouth = 'Nov-';
      break;
    case '12':
      mouth = 'Dec-';
      break;
    default:
      break;
  }

  const D = date.getUTCDate() < 10 ? '0' + date.getUTCDate() + '-' : date.getUTCDate() + '-';
  const h = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
  const m = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
  const s = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();

  return mouth + D + Y + ' ' + h + ':' + m + ':' + s + ' UTC';
};
