import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUTCTime } from 'utils';

export const Index: FC = () => {
  const { txId } = useParams();
  const [crossData, setCrossData] = useState<any>();

  useEffect(() => {
    fetch(`https://explorer.poly.network/api/v1/getcrosstx?txhash=${txId}`)
      .then(res => res.json())
      .then(res => {
        if (res.crosstransfer != null) {
          setCrossData(res);
        }
      });
  }, [txId]);

  if (crossData == null) {
    return null;
  }

  return (
    <div className="bg-gray-800 p-[30px]">
      <h2 className="mt-0 text-lg font-bold">1. Sending Transaction</h2>
      <div>
        <p>
          <span>Hash:</span> {}
          <a
            href={`${crossData.fchaintx.chainExplorerUrl}/tx/${crossData.fchaintx.txhash}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.fchaintx.txhash}
          </a>
        </p>
        <p>
          <span>Blockchain:</span> {crossData.fchaintx.chainname}
        </p>
        <p>
          <span>Asset:</span> {crossData.fchaintx.transfer.tokenname}
        </p>
        <p>
          <span>Token Type:</span> {crossData.fchaintx.transfer.tokentype}
        </p>
        <p>
          <span>Height:</span>
          <a
            href={`${
              crossData.fchaintx.chainExplorerUrl
            }/block/${crossData.fchaintx.blockheight.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.fchaintx.blockheight.toString()}
          </a>
        </p>
        <p>
          <span>Time:</span> {getUTCTime(crossData.fchaintx.timestamp * 1000)}
        </p>
        <p>
          <span>Transaction Type:</span> Send to Poly-chain
        </p>
        <p>
          <span>Fee:</span> {crossData.fchaintx.fee}
        </p>
        <p>
          <span>Status:</span> {crossData.fchaintx_valid === true ? 'Confirmed' : 'Pending'}
        </p>
        <p>
          <span>From Address:</span>{' '}
          <a
            href={`${crossData.fchaintx.chainExplorerUrl}/address/${crossData.fchaintx.transfer.from}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.fchaintx.transfer.from + ' (' + crossData.fchaintx.chainname + ')'}
          </a>
        </p>
        <p>
          <span>To Address:</span>{' '}
          <a
            href={`${crossData.fchaintx.chainExplorerUrl}/address/${crossData.fchaintx.transfer.to}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.fchaintx.transfer.to + ' (' + crossData.fchaintx.chainname + ')'}
          </a>
        </p>
      </div>
      <h2 className="text-lg font-bold">2. Poly-chain Transaction</h2>
      <div>
        <p>
          <span>Hash:</span> {crossData.mchaintx.txhash}
        </p>
        <p>
          <span>Blockchain:</span> {crossData.mchaintx.chainname}
        </p>
        <p>
          <span>Height:</span> {crossData.mchaintx.blockheight.toString()}
        </p>
        <p>
          <span>Time:</span> {getUTCTime(crossData.mchaintx.timestamp * 1000)}
        </p>
        <p>
          <span>Type:</span> Relay Transaction
        </p>
        <p>
          <span>Status:</span> {crossData.mchaintx_valid === true ? 'Confirmed' : 'Pending'}
        </p>
        <p>
          <span>From Address:</span>{' '}
          <a
            href={`${crossData.crosstransfer.fromchainexplorer}/address/${crossData.crosstransfer.fromaddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.crosstransfer.fromaddress + ' (' + crossData.mchaintx.fchainname + ')'}
          </a>
        </p>
        <p>
          <span>To Address:</span>{' '}
          <a
            href={`${crossData.crosstransfer.tochainexplorer}/address/${crossData.crosstransfer.toaddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {crossData.crosstransfer.toaddress + ' (' + crossData.mchaintx.tchainname + ')'}
          </a>
        </p>
      </div>
      <h2 className="text-lg font-bold">3. To Chain Transaction</h2>
      {crossData.tchaintx == null ? (
        <span>In Progress</span>
      ) : (
        <div>
          <p>
            <span>Hash:</span>
            <a
              href={`${crossData.tchaintx.chainExplorerUrl}/tx/${crossData.tchaintx.txhash}`}
              target="_blank"
              rel="noreferrer"
            >
              {crossData.tchaintx.txhash}
            </a>
          </p>
          <p>
            <span>Blockchain:</span> {crossData.tchaintx.chainname}
          </p>
          <p>
            <span>Asset:</span> {crossData.tchaintx.transfer.tokenname}
          </p>
          <p>
            <span>Token Type:</span> {crossData.tchaintx.transfer.tokentype}
          </p>
          <p>
            <span>Height:</span>
            <a
              href={`${
                crossData.tchaintx.chainExplorerUrl
              }/block/${crossData.tchaintx.blockheight.toString()}`}
              target="_blank"
              rel="noreferrer"
            >
              {crossData.tchaintx.blockheight.toString()}
            </a>
          </p>
          <p>
            <span>Time:</span> {getUTCTime(crossData.tchaintx.timestamp * 1000)}
          </p>
          <p>
            <span>Transaction Type:</span> Receive from Poly-chain
          </p>
          <p>
            <span>Fee:</span> {crossData.tchaintx.fee}
          </p>
          <p>
            <span>Status:</span> {crossData.tchaintx_valid === true ? 'Confirmed' : 'Pending'}
          </p>
          <p>
            <span>From Address:</span>{' '}
            <a
              href={`${crossData.tchaintx.chainExplorerUrl}/address/${crossData.tchaintx.transfer.from}`}
              target="_blank"
              rel="noreferrer"
            >
              {crossData.tchaintx.transfer.from + ' (' + crossData.tchaintx.chainname + ')'}
            </a>
          </p>
          <p>
            <span>To Address:</span>{' '}
            <a
              href={`${crossData.tchaintx.chainExplorerUrl}/address/${crossData.tchaintx.transfer.to}`}
              target="_blank"
              rel="noreferrer"
            >
              {crossData.tchaintx.transfer.to + ' (' + crossData.tchaintx.chainname + ')'}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};
